interface ModalConfirmationHookOptions {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const useModalConfirmation = ({
  title,
  subtitle,
  onConfirm,
  onCancel,
}: ModalConfirmationHookOptions) => {
  const { open } = useModal("confirmation");

  open({ title, subtitle }, { onConfirm, onCancel });
};
